/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DistribuidorFiltro } from "../../../pages/dashboard/models/distribuidor-filtro";
import { Distributor, DistributorData } from '../models/distributor';
import { Usuario } from '../models/usuario';
import { UrlService } from '../../../@core/services/urlService/url.service';
import { DistribuidorFullData } from '../../dashboard/models/DistribuidorFullData';
import { map } from 'rxjs/operators';
import { HttpCacheableService } from '../../../@core/services/cachingService/http-cacheable.service';
import { CacheService } from '../../../@core/services/cachingService/cache.service';

@Injectable()
export class DistributorService extends DistributorData {
  private apiRoute: string;
  constructor(private urlService: UrlService, private http: HttpClient, private httpCacheable: HttpCacheableService,
    private cacheService: CacheService) {
    super();
    this.apiRoute = `${this.urlService.getBaseUrl()}Distribuidor/`;
  }

  getList(): Observable<Distributor[]> {
    return this.httpCacheable.get<Distributor[]>({ url: `${this.apiRoute}GetDistribuidores`, cacheSecs: 5 }).pipe(
      map(dist => dist.sort(this.sortByName),
      ));
  }

  getDistribuidoresByChannel(channelId: number): Observable<Distributor[]> {
    return this.http.get<Distributor[]>(`${this.apiRoute}GetDistribuidoresByChannel`, { params: { channelId: channelId.toString() } }).pipe(
      map(dist => dist.sort(this.sortByName),
      ));
  }

  getDistribuidoresByChannelComCategoria(channelId: number): Observable<Distributor[]> {
    return this.http.get<Distributor[]>(`${this.apiRoute}GetDistribuidoresByChannelComCategoria`, { params: { channelId: channelId.toString() } }).pipe(
      map(dist => dist.sort(this.sortByName),
      ));
  }

  getDistribuidoresFiltroByChannel(channelId: number): Observable<DistribuidorFiltro[]> {
    return this.http.get<DistribuidorFiltro[]>(`${this.apiRoute}GetDistribuidoresByChannel`, { params: { channelId: channelId.toString() } }).pipe(
      map(dist => dist.sort(this.sortByName),
      ));
  }

  getDistribuidoresFiltroByChannelComCategoria(channelId: number): Observable<DistribuidorFiltro[]> {
    return this.http.get<DistribuidorFiltro[]>(`${this.apiRoute}GetDistribuidoresByChannelComCategoria`, { params: { channelId: channelId.toString() } }).pipe(
      map(dist => dist.sort(this.sortByName),
      ));
  }

  getAllDistribuidoresParaFiltro(): Observable<DistribuidorFiltro[]> {
    return this.httpCacheable.get<DistribuidorFiltro[]>({ url: `${this.apiRoute}GetAllDistribuidoresParaFiltro`, cacheSecs: 5 }).pipe(
      map(dist => dist.sort(this.sortByName),
      ));
  }

  getDistribuidoresFiltroByChannelByRole(channelId: number, isChannel: boolean): Observable<DistribuidorFiltro[]> {

    if (isChannel) {
      return this.getDistribuidoresFiltroByChannelComCategoria(channelId);
    }
    else {
      return this.getAllDistribuidoresParaFiltro();
    }
  }

  getDistribuidoresByChannelCompleto(channelId: number): Observable<Distributor[]> {
    return this.http.get<Distributor[]>(`${this.apiRoute}GetDistribuidoresByChannelCompleto`, { params: { channelId: channelId.toString() } }).pipe(
      map(dist => dist.sort(this.sortByName),
      ));
  }

  getDistribuidoresByGerente(gerenteId: number): Observable<Distributor[]> {
    return this.http.get<Distributor[]>(`${this.apiRoute}GetDistribuidoresByGerente`, { params: { gerenteId: gerenteId.toString() } }).pipe(
      map(dist => dist.sort(this.sortByName),
      ));
  }

  getDistribuidoresByGerenteCompleto(gerenteId: number): Observable<Distributor[]> {
    return this.http.get<Distributor[]>(`${this.apiRoute}GetDistribuidoresByGerenteCompleto`, { params: { gerenteId: gerenteId.toString() } }).pipe(
      map(dist => dist.sort(this.sortByName),
      ));
  }

  getAllDistribuidores(): Observable<Distributor[]> {
    return this.http.get<Distributor[]>(`${this.apiRoute}GetAllDistribuidores`).pipe(
      map(dist => dist.sort(this.sortByName),
      ));
  }

  update(formData: FormData, id: number): Observable<any> {
    this.cacheService.remove(`${this.apiRoute}GetDistribuidores`);
    return this.http.post<any>(`${this.apiRoute}EditarDistribuidor/${id}`, formData);
  }

  getCategoriasDistByDistId(distribuidorId: number): Observable<DistribuidorFullData> {
    return this.http.get<DistribuidorFullData>(`${this.apiRoute}getCategoriasDistByDistId/${distribuidorId}`);
  }

  cargaDistribuidoresExcel(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiRoute}CargaDistribuidoresExcel/`, formData);
  }

  getUserByDistId(distribuidorId: number): Observable<Usuario> {
    return this.http.get<any>(`${this.apiRoute}getUserByDistId/${distribuidorId}`);
  }

  disableUserByDistId(distribuidorId: number): Observable<any> {
    return this.http.get<any>(`${this.apiRoute}disableUserByDistId/${distribuidorId}`);
  }

  private sortByName(a, b): number {
    if (a.nombre > b.nombre) { return 1; }
    if (a.nombre < b.nombre) { return -1; }
    return 0;
  }
}
