import { UrlService } from '../../../@core/services/urlService/url.service';
import { ManualData, Manual } from '../models/manual';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ManualService extends ManualData {
  private apiRoute: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    super();
    this.apiRoute = `${this.urlService.getBaseUrl()}Manual/`;
  }

  create(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiRoute}CrearManual`, formData);
  }

  update(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiRoute}EditarManual`, formData);
  }

  getList(): Observable<Manual[]> {
    return this.http.get<Manual[]>(`${this.apiRoute}GetManuais/`);
  }

  getByRolAndIdioma(roldId, idioma): Observable<Manual> {
    return this.http.get<Manual>(`${this.apiRoute}GetManualByRolAndIdioma/${roldId}/${idioma}`);
  }

  delete(id: number): Observable<any> {
    return this.http.post<any>(`${this.apiRoute}EliminarManual/${id}`, {});
  }
}
