<div class="header-container d-flex justify-content-between">
  <div class="header__logos">
    <img src="../../../../assets/svg/BDLogo.svg" class="img-fluid">
    <img src="../../../../assets/images/NexoLogo.png" class="img-fluid">
  </div>
  <nb-actions>
    <nb-action class="user-action">
      <ng-container *ngIf="!checkLocalStorage(); else elseNbUser">
        <a href="#" class="link-icon" [title]="dashboardTooltip">
          <i class="fas fa-home fa-2x"></i>
        </a>
        <nb-user [nbContextMenu]="userMenu" [name]="usuarioLogueado" [title]="rolUsuarioLogueado"
                 [picture]="fotoPerfil ? fotoPerfil: null">
        </nb-user>
        <!--<div *ngIf="pendingCount > 0" class="corner-icon" title="Count">
    <a href="/#/pages/admin">
      <div class="fa fa-icon fa-1x">{{pendingCount}}</div>
    </a>
  </div>-->
      </ng-container>
      <ngx-language></ngx-language>      
      <ng-template #elseNbUser>
        <a href="#/pages/dashboard/{{usuarioVistaDistribuidor.distribuidorId}}" class="link-icon"
          [title]="dashboardTooltip">
          <i class="fas fa-home fa-2x"></i>
        </a>
        <nb-user [nbContextMenu]="userMenuVistaDistribuidor" [name]="usuarioVistaDistribuidor.distribuidor.nombre"
          [title]="'Distribuidor'"
          [picture]="usuarioVistaDistribuidor.fotoPerfil ? usuarioVistaDistribuidor.fotoPerfil: null">
        </nb-user>
      </ng-template>
    </nb-action>
  </nb-actions>
</div>
