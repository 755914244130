import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { Config } from '../config/config';
import { UserService } from '../services/userService/user.service';
import { environment as env } from '../../../environments/environment';

function until(conditionFunction) {

  const poll = resolve => {
    if (conditionFunction()) resolve();
    else setTimeout(_ => poll(resolve), 200);
  }

  return new Promise(poll);
}

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

  userService: UserService;
  rol: string;

  constructor(private router: Router, userService: UserService) {
    this.userService = userService;

    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('token');
    let paisSessao = urlParams.get('pais');

    if (token == null || token == undefined || token.length == 0) {
      token = sessionStorage.getItem(Config.LocalStorage.auth0Token)
      paisSessao = sessionStorage.getItem(Config.LocalStorage.paisSessao)
      if (token == null) {
        token = this.getCookie("tokenNexo");
        paisSessao = this.getCookie("paisSessao");
      }
        
    }
    sessionStorage.clear();

    sessionStorage.setItem(Config.LocalStorage.auth0Token, token);
    if (paisSessao != null)
      sessionStorage.setItem(Config.LocalStorage.paisSessao, paisSessao.toUpperCase());

    if (token != null && token != "") {
      this.setCookie("tokenNexo", token, 1);
      this.setCookie("paisSessao", paisSessao, 1);
    }
     
    try {
      let email = ''; 
      const getEmail = fetch(env.urlSSO + 'GetChangeUser', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
        .catch((ex) => {
          sessionStorage.clear();
          this.expirarSessao()
        });

      getEmail.then((resEmail) => {
        email = resEmail.email

        const getUser = fetch(env.urlSSO + 'Get?email=' + email, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
        .catch((ex) => {
          sessionStorage.clear();
          this.router.navigateByUrl('/bienvenido');
        });;

        getUser.then((resUser) => {
          sessionStorage.setItem(Config.LocalStorage.auth0Id, resUser.id);

          this.userService.setUsuario();

          this.userService.getRole()
            .subscribe(rol => {
              this.rol = rol;
            });
        });
      });
    }
    catch (error) {
      sessionStorage.clear();
      this.router.navigateByUrl('/bienvenido');
    }
  }

  private getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  private expirarSessao() {
    let pais = "pt-br";
    let paisAtual = sessionStorage.getItem(Config.LocalStorage.paisSessao);
    if (paisAtual == null)
      paisAtual = this.getCookie('paisSessao');

    if (paisAtual == "AR")
      pais = "es-ar"
    else if (paisAtual == "CO")
      pais = "es-co"
    else if (paisAtual == "CL")
      pais = "es-cl"
    else if (paisAtual == "PE")
      pais = "es-pe"
    else if (paisAtual == "MX")
      pais = "es-mx"

    window.location.href = Config.LocalStorage.urlPortal + pais + "/?sessaoExpirouNexo=sessaoExpirouNexo";
    return false;
  }

  async canAccess(roles: string[], acceso?: number) {
    let token = sessionStorage.getItem(Config.LocalStorage.auth0Token)
    if (token == null || token == "") {
      token = this.getCookie("tokenNexo");
      sessionStorage.setItem(Config.LocalStorage.auth0Token, token);
      sessionStorage.setItem(Config.LocalStorage.paisSessao, this.getCookie("paisSessao"));
    }

    const getEmail = fetch(env.urlSSO + 'GetChangeUser', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response.status == 200)
          return response.json()
        return { code: 401 }
      })
      .then((data) => {
        return data;
      });

    getEmail.then((resEmail) => {
      if (resEmail.code != 200) {
        token = null
        sessionStorage.setItem(Config.LocalStorage.auth0Token, "");

        return this.expirarSessao();
      }
    });

    await until(_ => sessionStorage.getItem(Config.LocalStorage.usuarioSeteado));
    if (token != null && token != undefined && token.length > 0) {
      if (!sessionStorage.getItem(Config.LocalStorage.usuarioLogueado)) {
        sessionStorage.clear();
        this.router.navigateByUrl('/activar-usuario');
        return false;
      }
      if (!this.userService.hasRole(roles) || (acceso && !this.userService.tieneAcceso(acceso))) {
        sessionStorage.clear();
        this.router.navigateByUrl('/');
        return false;
      }
      return true;
    }
    else {
      return this.expirarSessao();
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return route.data.acceso ? this.canAccess(route.data.roles, route.data.acceso) : this.canAccess(route.data.roles);
  }

  canLoad(route: Route) {
    return route.data.acceso ? this.canAccess(route.data.roles, route.data.acceso) : this.canAccess(route.data.roles);
  }
}
