import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlService } from './urlService/url.service';
import { Pais, PaisData } from '../../pages/admin/models/pais';
import { PaisTipoCambio } from '../../pages/admin/models/pais-tipo-cambio';
import { Grupo } from '../../pages/admin/models/grupo';
import { HttpCacheableService } from './cachingService/http-cacheable.service';
import { CacheService } from './cachingService/cache.service';

@Injectable()
export class PaisService extends PaisData {
  private apiRoute: string;

  get todosGrupo(): Grupo { return { id: 0, nombre: 'Todos', paises: [], paisesDisplay: '' }; }
  get todos(): Pais { return { id: 0, nombre: 'Todos', codigo: 'Todos', moneda: 'USD', idioma: '', tipoCambio: 1, descuentoCategoria: true, mostrarCategoria: true, autoAprobacionInventario: false, autoAprobacionSellOut: false, modulos: [], grupos: [], grupoDisplay: '' }; }

  constructor(private urlService: UrlService, private http: HttpClient, private httpCacheable: HttpCacheableService,
    private cacheService: CacheService) {
    super();
    this.apiRoute = `${this.urlService.getBaseUrl()}Pais/`;
  }

  getList(): Observable<Pais[]> {
    return this.http.get<Pais[]>(`${this.apiRoute}GetPaises`);
  }

  getListConModulos(): Observable<Pais[]> {
    return this.http.get<Pais[]>(`${this.apiRoute}GetPaisesyModulos`);
  }

  getPaisByCodigo(codigo: string): Observable<Pais> {
    return this.http.get<Pais>(`${this.apiRoute}GetPaisByCodigo`, { params: { codigo: codigo } });
  }

  getTiposCambio(): Observable<PaisTipoCambio[]> {
    return this.http.get<PaisTipoCambio[]>(`${this.apiRoute}GetTiposCambio`);
  }

  update(tipoCambio: PaisTipoCambio): Observable<any> {
    return this.http.post<any>(`${this.apiRoute}UpdateTipoCambio/`, tipoCambio);
  }

  getListStartingWithTodos(): Observable<Pais[]> {
    const todos$ = of([this.todos]);
    return forkJoin([todos$, this.getList()])
      .pipe(map(([t, r]) => [...t, ...r]));
  }

  getGruposListStartingWithTodos(): Observable<Grupo[]> {
    const todos$ = of([this.todosGrupo]);
    return forkJoin([todos$, this.getGruposList()])
      .pipe(map(([t, r]) => [...t, ...r]));
  }

  getListMultiPaises(userId): Observable<Pais[]> {
    return this.http.get<Pais[]>(`${this.apiRoute}getListMultiPaises`, { params: { userId: userId } });
  }

  updatePais(pais: Pais): Observable<any> {
    return this.http.post<any>(`${this.apiRoute}UpdatePais/`, pais);
  }

  updatePaisConModulos(pais: Pais): Observable<any> {
    return this.http.post<any>(`${this.apiRoute}UpdatePaisConModulos/`, pais);
  }

  getGruposList(): Observable<Grupo[]> {
    return this.httpCacheable.get<Grupo[]>({ url: `${this.apiRoute}GetGruposPaises`, cacheSecs: 5 });
  }

  createGrupoPaises(grupo: Grupo): Observable<any> {
    this.cacheService.remove(`${this.apiRoute}GetGruposPaises`);
    return this.http.post<any>(`${this.apiRoute}CreateGrupoPaises/`, grupo);
  }

  updateGrupoPaises(grupo: Grupo): Observable<any> {
    this.cacheService.remove(`${this.apiRoute}GetGruposPaises`);
    return this.http.post<any>(`${this.apiRoute}UpdateGrupoPaises/`, grupo);
  }

  deleteGrupoPaises(grupoId: number): Observable<any> {
    this.cacheService.remove(`${this.apiRoute}GetGruposPaises`);
    return this.http.post<any>(`${this.apiRoute}DeleteGrupoPaises/${grupoId}`, {});
  }
}
