import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from '../../../@core/services/urlService/url.service';
import { SellOut } from '../../dashboard/models/carga-mensual-distribuidor';
import { FactorConversionBd } from '../models/factorConversionBd';
import { Respuesta } from '../models/respuesta';
import { SellOutCarga } from '../models/sellOutCarga';
import { CargaMensualRespuesta } from '../../dashboard/models/carga-mensual-respuesta';

@Injectable()
export class FactorConversionBdService {
  private apiRoute: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
      this.apiRoute = `${this.urlService.getBaseUrl()}FactorConversionBd/`;
  }

  getSellOutValidando(): Observable<SellOut[]> {
      return this.http.get<SellOut[]>(`${this.apiRoute}getSellOutByValidando`);
  }

  getSellOutNoCargados(): Observable<SellOut[]> {
      return this.http.get<SellOut[]>(`${this.apiRoute}getSellOutNoCargados`);
  }

  getSellOutCargaAdminByDistIdMesAndAnioFiscal(distribuidorId: number, mes: number, anioFiscal: number): Observable<SellOutCarga[]> {
      return this.http.get<SellOutCarga[]>(`${this.apiRoute}GetSellOutCargaAdminByDistIdMesAndAnioFiscal/${distribuidorId}/${mes}/${anioFiscal}`);
  }

  getSellOutByDistIdMesAndAnioFiscal(distribuidorId: number, mes: number, anioFiscal: number): Observable<SellOut> {
      return this.http.get<SellOut>(`${this.apiRoute}GetSellOutByDistIdMesAndAnioFiscal/${distribuidorId}/${mes}/${anioFiscal}`);
  }

  removeSellOutCargaById(sellOutCargaId: number, sellOutId: number): Observable<string[]> {
      return this.http.delete<string[]>(`${this.apiRoute}removeSellOutCarga/${sellOutCargaId}/${sellOutId}`);
  }

  updateSellOutCarga(carga: SellOutCarga): Observable<string[]> {
      return this.http.post<string[]>(`${this.apiRoute}updateSellOutCarga`, carga);
  }

  createSellOutCarga(distribuidorId: number, mes: number, carga: SellOutCarga, anioFiscal: number): Observable<SellOutCarga> {
      return this.http.post<SellOutCarga>(`${this.apiRoute}createSellOutCarga/${distribuidorId}/${mes}/${anioFiscal}`, carga);
  }

  aprobarSellOut(sellOutId: number): Observable<SellOut> {
      return this.http.post<SellOut>(`${this.apiRoute}aprobarSellOut`, sellOutId);
  }

  rechazarSellOut(sellOutId: number): Observable<SellOut> {
      return this.http.post<SellOut>(`${this.apiRoute}rechazarSellOut`, sellOutId);
  }

  borrarSellout(sellOutId: number): Observable<SellOut> {
      return this.http.post<SellOut>(`${this.apiRoute}borrarSellout`, sellOutId);
  }

  getFactorConversion(): Observable<FactorConversionBd[]> {
    return this.http.get<FactorConversionBd[]>(`${this.apiRoute}getFactorConversion`);
  }

  removeFactorConversionById(codigoArticulo: string, codigoPais: string): Observable<string[]> {
      return this.http.delete<string[]>(`${this.apiRoute}removerFactorConversion/${codigoArticulo}/${codigoPais}`);
  }

  updateFactorConversion(factorConversion: FactorConversionBd): Observable<Respuesta> {
      return this.http.post<Respuesta>(`${this.apiRoute}actualizarFactorConversion`, factorConversion);
  }

  createFactorConversion(factorConversion: FactorConversionBd): Observable<Respuesta> {
      return this.http.post<Respuesta>(`${this.apiRoute}createFactorConversion`, factorConversion);
  }

  CargarExcelFactoresConversion(formData: FormData): Observable<any> {
      return this.http.post<any>(`${this.apiRoute}CargarExcelFactoresConversion/`, formData);
  }

  getCantidadSelloutPendientesDeAprobacion(): Observable<number> {
      return this.http.get<number>(`${this.apiRoute}getSelloutPendientesDeAprobacion`);
  }

  cargarExcel(formData: FormData): Observable<CargaMensualRespuesta> {
    return this.http.post<CargaMensualRespuesta>(`${this.apiRoute}cargarExcel`, formData);
  }
}
