import { Rol } from './rol';
import { Observable } from 'rxjs';

export class Manual {
  id: number;
  rol: Rol[];
  rolId: number;
  idioma: string;
  conteudo: string;
}

export abstract class ManualData {
  abstract getList(): Observable<Manual[]>;
  abstract create(formData: FormData): Observable<any>;
  abstract update(formData: FormData): Observable<any>;
  abstract delete(documentId: number): Observable<any>;

}
