export const Config = {
  CargaMensual: {
    Estados: {
      Cargado: 'Cargado',
      NoCargado: 'No Cargado',
      VolverACargar: 'Volver a cargar',
      Validando: 'Validando',
      NoDisponible: 'No disponible aún',
      CargarAhora: 'Cargar ahora',
    },
    Sellout: {
      //Los textos ahora están en los archivos de traducción bajo el nombre 'carga-mensual/Sellout'
      Excel: {
        TemplateURL: 'Recursos\\Repositorio\\TEMPLATE_SELLOUT_CARGA',
        TemplateURLBrasil: 'Recursos\\Repositorio\\TEMPLATE_SELLOUT_CARGA_BR',
        TemplateURLMexico: 'Recursos\\Repositorio\\TEMPLATE_SELLOUT_CARGA_MX',
        TemplateURLColombia: 'Recursos\\Repositorio\\TEMPLATE_SELLOUT_CARGA_CO',
      }
    },
    CategoriaArticulo: {
      Excel: {
        TemplateURL: 'Recursos\\Repositorio\\TEMPLATE_CATEGORIA_ARTICULOS'
      }
    },
    Inventario: {
      //Los textos ahora están en los archivos de traducción bajo el nombre 'carga-mensual/Inventario'
      Excel: {
        TemplateURL: 'Recursos\\Repositorio\\TEMPLATE_INVENTARIO_CARGA',
        TemplateURLMexico: 'Recursos\\Repositorio\\TEMPLATE_INVENTARIO_CARGA_MX',
      }
    }
  },
  // Los textos de la AprobacionSellOut ahora están en los archivos de traducción bajo el nombre aprobacion-sell-out
  // Los textos de la ABMFactorConversion ahora están en los archivos de traducción bajo el nombre factor-conversion
  // Los textos de la ABMContactos ahora están en los archivos de traducción bajo el nombre abmContactos
  ABMObjetivos: {
    DefaultMenuText: 'Carga de Objetivos',
    GerenteMenuText: 'Aprobación de Objetivos',
  },
  // Los textos de la TargetSaveMessage ahora están en los archivos de traducción bajo el nombre 'target'
  // Los textos de la TargetAproveMessage ahora están en los archivos de traducción bajo el nombre 'target'
  // Los textos de la TargetRejectMessage ahora están en los archivos de traducción bajo el nombre 'target'
  Roles: {
    ChannelMultiPaises: 'Channel Multi países',
    Distribuidor: 'Distribuidor',
    ChannelReferente: 'Channel Referente',
    Gerente: 'Gerente',
    SuperGerente: 'Super Gerente',
    Administrador: 'Administrador',
  },
  RolClaim: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  Compliance: {
    //Los textos ahora están en los archivos de traducción bajo el nombre 'compliance'
    DialogFilter: '.xlsx,.xls',
    TemplateURL: 'Recursos\\Repositorio\\TEMPLATE_COMPLIANCE',
    TemplateFileName: 'TEMPLATE_COMPLIANCE.xlsx',
    Estados: {
      Completo: { Id: 1, Descripcion: 'Completo' },
      Incompleto: { Id: 2, Descripcion: 'Incompleto' },
    },
  },
  FotoPerfil: {
    Max_size: 2097152,
    ErrorSubida: 'La imagen debe tener menos de 2 MB no se puede cargar la imagen',
    ErrorTamaño: 'Atención',
  },
  // Los textos de ABMUsuarios ahora están en los archivos de traducción bajo el nombre 'usuario'
  // Los textos de ABMIntegrantes ahora están en nuevos archivos de traducción assets/i18n/integrante'
  // Los textos de ABMDistribuidores ahora están en los archivos de traducción bajo el nombre 'distribuidor'
  // Los textos de ABMRepositorio ahora están en los archivos de traducción bajo el nombre 'repositorio'

  ABMBanners: {
    Max_size: 5242880,
    Min_width: 1400,
    Min_height: 400,
    //Los textos ahora están en nuevos archivos de traducción únicos para todo lo relacionado con ABMBanners en assets/i18n/banners
  },
  DefCategorias: {
    //Los textos ahora están en los archivos de traducción bajo el nombre def-categorias
    Categorias: {
      Black: 'Black',
      Platinum: 'Platinum',
      Gold: 'Gold',
      SinCategoria: 'Sin categoría'
    },
  },
  DefCategoriasAprobacion: {
    //Los textos ahora están en los archivos de traducción bajo el nombre def-categorias
    Categorias: {
      Black: 'Black',
      Platinum: 'Platinum',
      Gold: 'Gold',
      SinCategoria: 'Sin categoría'
    },
  },
  ActualizacionChannel: {
    Perfil: {
      Distribuidor: {
        Id: 5,
        Nombre: 'Distribuidor'
      },
      Channel: {
        Id: 4,
        Nombre: 'Channel'
      },
      ChannelMultiPais: {
        Id: 6
      }
    },
    Mensaje: {
      Exito: "El Actualizacion Channel fue modificado exitosamente",
      Danger: "El Actualizacion Channel no fue modificado exitosamente"
    }
  },
  Auth: {
    RecuperarContraseña: {
      MensajeFallo: 'Fallo al recuperar contraseña',
    },
    CrearContraseña: {
      TituloFallo: 'Fallo al crear nueva contraseña',
      MensajeFallo: 'El tiempo provisto para utilizar el enlace de creación expiró.',
      TituloExito: 'Creación exitosa',
      MensajeExito: 'Nueva contraseña creada correctamente, ahora puedes ingresar.',
    },
  },
  SellOutResumen: {
    EstadosId: {
      Incompleto: 1,
      Completo: 2,
      Proximamente: 3,
    },
  },
  SellinVsObjetivosResumen: {
    EstadosId: {
      NoAlcanzado: 1,
      Alcanzado: 2,
      Proximamente: 3,
      MesActual: 4,
      NoDisponible: 5,
    },
  },
  Target: {
    EstadosId: {
      NoCargado: 1,
      PendienteAprobacion: 2,
      Aprobado: 3,
      Rechazado: 4,
    },
  },
  Modulos: {
    Compliance: 'COMPLIANCE',
    ResumenDeCuenta: 'RESUMEN_DE_CUENTA',
    CumplimientoSellout: 'CUMPLIMIENTO_SELLOUT',
    CumplimientoObjetivos: 'CUMPLIMIENTO_OBJETIVOS',
    Rebate: 'REBATE',
    EnvioSellout: 'ENVIO_SELLOUT',
    EnvioInventario: 'ENVIO_INVENTARIO',
    SellInVsObjetivos: 'GRAFICOS_SELLINOBJETIVOS',
    CargaMassiva: 'CARGA_MASIVA',
  },
  LocalStorage: {
    Dist: 'DIST',
    usuarioLogueado: 'usuarioLogueado',
    rolUsuarioLogueado: 'rolUsuarioLogueado',
    fotoPerfil: 'fotoPerfil',
    auth0Token: 'auth0Token',
    auth0Id: 'auth0Id',
    usuarioSeteado: 'usuarioSeteado',
    redirect: 'redirect',
    paisSessao: 'paisSessao',
    //urlPortal: '//dwil0699latam01.bd.com/'
    urlPortal: '//portalclientes.bd.com/'
  },
  Paises: {
    ArgentinaCodigo: 'AR',
    BrasilCodigo: 'BR',
    MexicoCodigo: 'MX',
    ColombiaCodigo: 'CO',
    PeruCodigo: 'PE',
    //Los textos ahora están en nuevos archivos i18n con todo lo relacionado a parametrizacion de paises en assets/i18n/parametrizapais
    Hubs: {
      Andean: 'ANDEAN',
      Brasil: 'BRASIL',
      Mexico: 'MEXICO',
      Soco: 'SOCO',
    }
  },
  TablaSinDatos: 'No se encontraron resultados.',
  Fechas: {
    qAnioFiscalQAnioCalendario: {
      1: 4,
      2: 1,
      3: 2,
      4: 3,
    }
  },
  LogSesion: {
    LogTxtName: 'LogSesion.txt',
    TituloError: 'Fechas inválidas',
    MensajeError: 'La fecha de inicio debe ser anterior a la fecha de fin.',
  },
  ABMTipoCambio: {
    Titulo: 'ABM Tipo de Cambio',
    Editar: {
      Titulo: 'Editar Tipo de Cambio',
      TituloExito: 'Modificación Exitosa',
      MensajeExito: 'El tipo de cambio fue modificado exitosamente',
      TituloFallo: 'Modificación Fallida',
    },
    ValorInvalido: 'El valor de cambio no puede estar vacío y debe ser un número mayor a 0',
  },
  Reportes: {
    ExcelURL: 'Recursos\\Reportes\\BD-Reportes',
    ExcelFileName: 'BD-Reportes.xlsx',
    CvsSelloutFileName: "Sellout.csv",
    CvsDetalleInventarioFileName: "Inventario.csv",
    CvsMasterDistribuidorFileName: "MasterDistribuidor.csv"
  },
  HistorialRebate: {
    ExcelURL: 'Recursos\\HistorialRebate\\Rebate-',
    ExcelExtension: '.xlsx'
  },
  CargaFactoresConversion: {
    //Los textos ahora están en los archivos de traducción bajo el nombre factor-conversion.carga-excel
    DialogFilter: '.xlsx,.xls',
  },
  Idiomas: [
    { id: 'es', descripcion: 'Español' },
    { id: 'pt', descripcion: 'Portuguese' },
  ],
  //Los textos de ABMGrupo y todo lo relacionado ahora están en nuevos archivos de traducción únicos en assets/i18n/grupopais
  smartTableDefaultSettings: {
    columns: {
      uno: {
        title: '',
      },
      dos: {
        title: '',
      },
      tres: {
        title: '',
      },
      cuatro: {
        title: '',
      },
    },
    mode: 'inline',
    hideHeader: 'true',
    actions: {
      columnTitle: '',
      add: false,
      edit: false,
      delete: false
    }
  }
};
